import React from 'react'
import './homepage.sass'
import { useState } from 'react'
import BackImg from '../assets/bg-pattern-desktop.svg'
import DeskImg from '../assets/harmoney-academy-vanessa-pineda-desktop.webp'
import TabImg from '../assets/harmoney-academy-vanessa-pineda-mobile.webp'
import Arrow from '../assets/icon-arrow.svg'
import Error from '../assets/icon-error.svg'


function Homepage() {
  let hideError = {
    opacity: 0,
  }
  const [error, setError] = useState(hideError);
  const [btnStatus, setBtnStatus] = useState(true);

  const checkMail = (event) => {
    let pattern = /^\w+([.-]?\w+)*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    let emailInput = event.target.value;
    let showError = {
      opacity: 1,
    }

    if (emailInput.match(pattern) || emailInput === '') {
      setError(hideError)
      event.target.style.border = "1px solid hsl(0, 36%, 80%)"
      setBtnStatus(false)
    }
    else {
      setError(showError)
      event.target.style.border = "2px solid hsl(0, 93%, 68%)"
      setBtnStatus(true)
    }
    if (emailInput === '') {
      setBtnStatus(true)
    }
  }

  return (
    <header>
      <div className="textcontent">
        <figure>
          <img src={BackImg} alt="" />
        </figure>
        <h1>Harmoney Academy</h1>
        <div className="text-area">
          <h1><span>BALD</span><br /> VERF&Uuml;GBAR <br /></h1>
          <p>Lerne mit der Harmoney Academy, wie du deine Finanzen einfach und klar organisierst, deine Werte lebst und eine Zukunft gestaltest, die wirklich zu dir passt.<br /><br /> Trage dich jetzt ein, um deinen Rabatt als BETTER YOU-Teilnehmer nicht zu verpassen!</p>
          <form name="signup" method="post">
            <input type="hidden" name="form-name" value="signup" />
            <input type="email" name="email" placeholder='E-Mail-Ad­res­se' id='mail' onInput={checkMail} />
            <label htmlFor="email" className='error' style={error}>Bitte eine gültige E-Mail-Ad­res­se angeben</label>
            <div className="error-icon error" style={error}><img src={Error} alt="" /></div>
            <button type="submit" disabled={btnStatus} value="" aria-label="submit"><img src={Arrow} alt="" /></button>
          </form>
        </div>
      </div>
      <picture>
        <source media="(max-width: 1024px)" srcset={TabImg} />
        <img src={DeskImg} alt="Vanessa Pineda" />
      </picture>
    </header>
  )
}

export default Homepage