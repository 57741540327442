import React from 'react'
import './nav.sass'
import Logo from '../assets/harmoney-academy-logo.webp'

function nav() {
  return (
    <nav><img src={Logo} alt="" /></nav>
  )
}

export default nav